import React from "react";
import UserSelect from "../../component/select/UserSelect";
import IApplicationModel from "../../model/ApplicationModel";
import IWorkspaceModel from "../../model/WorkspaceModel";
import Style from "./SelectPage.module.css";

interface SelectPageProps {
    workspace?: IWorkspaceModel
    application?: IApplicationModel
    redirectUrl?: string
}

const SelectPage: React.FC<SelectPageProps> = (props: SelectPageProps) => {
    return (
        <div className={Style.select_page_container}>
            {(props.application && props.workspace) && <UserSelect application={props.application} workspace={props.workspace} />}
        </div>
    )
}

export default SelectPage;