const KeyConstant = {
    workspaceId: 'workspace_id',
    accessKey: 'access_key',
    redirectUrl: 'redirect_url',
    applicationId: 'application_id',
    secretKey: 'secret_key',
    isDeeplink: 'is_deeplink',
    hash: '@',
    getToken: 'gt',
};

export default KeyConstant;
