export const GET_WORKSPACE_AND_APPLICATION = `
    query ($workspace_id: String!, $access_key: String!, $application_id: String!, $secret_key: String!) {
        workspace(id: $workspace_id, access_key: $access_key) {
            id
            name
            allow_sign_up
            email_domain
        }

        application(id: $application_id, secret_key: $secret_key, workspace_id: $workspace_id) {
            id
            name
        }
    }
`;
