import { IComponentError, IfCondition, useGRequest } from '@nochiri/ui';
import React, { useEffect, useState } from 'react';
import LoginForm from '../../component/LoginForm';
import LoginSuccessWithDeeplink from '../../component/LoginSuccessWithDeeplink';
import ErrorKey from '../../const/ErrorKeys';
import KeyConstant from '../../const/KeyConstant';
import { ILoginParams, LOGIN } from '../../const/MutationDefine';
import IApplicationModel from '../../model/ApplicationModel';
import { GraphQLError } from '../../model/GraphqlError';
import IWorkspaceModel from '../../model/WorkspaceModel';
import { errorMessage } from '../../util/ErrorMessage';
import Style from './LoginPage.module.css';

interface ILoginPageProps {
    workspace?: IWorkspaceModel;
    application?: IApplicationModel;
    redirectUrl?: string;
    isDeeplink?: boolean;
}

const LoginPage: React.FC<ILoginPageProps> = (props: ILoginPageProps) => {
    const [errors, setErrors] = useState<IComponentError[]>([]);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [tokenId, setTokenId] = useState<string>('');
    const [loginResponse, loginLoading, executeLogin] = useGRequest(LOGIN, (errorMessages?: GraphQLError[]) => {
        if (!errorMessages) {
            return;
        }

        const errors = errorMessages.map((error) => errorMessage(error.message as ErrorKey)).filter(Boolean);
        setErrors(errors as IComponentError[]);
    });

    useEffect(() => {
        if (loginResponse) {
            // check token is available
            if (loginResponse.login.token.id) {
                setTokenId(loginResponse.login.token.id);
                setLoginSuccess(true);
                if (!props.isDeeplink) {
                    // redirect to redirectUrl with token id
                    window.location.href = `${props.redirectUrl}?${KeyConstant.getToken}=${loginResponse.login.token.id}`;
                }
            }
        }
    }, [loginResponse, props.isDeeplink, props.redirectUrl]);

    /**
     * This function will use email and password for login to SSO system
     * @param email String email
     * @param password String password
     * @returns Void
     */
    const onLogin = (email: string, password: string) => {
        // TODO: validate form
        // Check only login if workspace is available
        if (props.workspace && props.application) {
            let username = email;
            if (email.includes(KeyConstant.hash)) {
                const [emaiUserName, domain] = email.toLowerCase().split(KeyConstant.hash);
                username = emaiUserName;

                // Validate email domain equal with domain in workspace
                if (domain !== props.workspace.email_domain) {
                    console.log('Not map with workspace domain'); // TODO: remove this and show a error message
                    return false;
                }
            }

            // generate login params
            const loginParams: ILoginParams = {
                email: `${username}${KeyConstant.hash}${props.workspace.email_domain}`,
                password,
                workspace_id: props.workspace.id,
                application_id: props.application.id,
            };

            // execute login
            executeLogin(loginParams);
        }
    };

    return (
        <div className={Style.login_page_container}>
            <IfCondition condition={!loginSuccess}>
                <IfCondition condition={!props.redirectUrl}>
                    <h5>Redirect Url not allow</h5>
                </IfCondition>

                <IfCondition condition={!props.workspace || !props.application}>
                    <h5>Application not found</h5>
                </IfCondition>

                <IfCondition condition={Boolean(props.redirectUrl && props.workspace && props.application)}>
                    <LoginForm
                        workspace={props.workspace!}
                        onLogin={onLogin}
                        errors={errors}
                        showLoading={loginLoading}
                    />
                </IfCondition>
            </IfCondition>
            <IfCondition condition={loginSuccess && props.isDeeplink && !!props.application && !!props.redirectUrl}>
                <LoginSuccessWithDeeplink
                    application={props.application!}
                    deeplink={props.redirectUrl!}
                    tokenId={tokenId}
                />
            </IfCondition>
        </div>
    );
};

export default LoginPage;
