import './App.css';
import '@nochiri/ui/dist/index.css';
import LoginPage from './page/login/LoginPage';
import SelectPage from './page/select/SelectPage';
import { useEffect, useState } from 'react';
import IWorkspaceModel from './model/WorkspaceModel';
import IApplicationModel from './model/ApplicationModel';
import KeyConstant from './const/KeyConstant';
import { UrlUtils } from '@nochiri/utils';
import { GET_WORKSPACE_AND_APPLICATION } from './const/QueryDefine';
import { Form, FormControl, FormGroup, GRequest, IfCondition, RouterDom, UILib, useGRequest } from '@nochiri/ui';
import AppConstant from './const/AppConstant';
import RouterConstants from './const/RouteConstants';

function App() {
    const [workspace, setWorkspace] = useState<IWorkspaceModel>();
    const [application, setApplication] = useState<IApplicationModel>();
    const [redirectUrl, setRedirectUrl] = useState<string>();
    const [isDeeplink, setIsDeeplink] = useState<boolean>(false);
    const [appKeysResponse, appKeysLoading, executeQueryAppKeys] = useGRequest(GET_WORKSPACE_AND_APPLICATION);

    useEffect(() => {
        GRequest.instance.initialize({
            baseUrl: AppConstant.apiEndPoint,
        });
    }, []);

    useEffect(() => {
        if (appKeysResponse) {
            const { workspace, application } = appKeysResponse;
            setWorkspace(workspace);
            setApplication(application);
        }
    }, [appKeysResponse]);

    useEffect(() => {
        // get information from query url
        const urlSearch = new URLSearchParams(window.location.search);
        const workspaceId = urlSearch.get(KeyConstant.workspaceId);
        const accessKey = urlSearch.get(KeyConstant.accessKey);
        const applicationId = urlSearch.get(KeyConstant.applicationId);
        const secretKey = urlSearch.get(KeyConstant.secretKey);
        const isDeeplink = urlSearch.get(KeyConstant.isDeeplink);

        setIsDeeplink(isDeeplink === 'true');

        // get redirect url and remove get token param
        const redirectUrl = urlSearch.get(KeyConstant.redirectUrl);
        if (redirectUrl) {
            setRedirectUrl(UrlUtils.removeQueryParam(redirectUrl, KeyConstant.getToken));
        }

        // check workspace id, access key, application id, secret key available
        if (workspaceId && accessKey && applicationId && secretKey) {
            // request data is available
            executeQueryAppKeys({
                workspace_id: workspaceId,
                access_key: accessKey,
                application_id: applicationId,
                secret_key: secretKey,
            });
        }
    }, [executeQueryAppKeys]);

    useEffect(() => {
        if (application) {
            window.document.title = application.name;
        }
    }, [application]);

    const mainElement = (
        <>
            <IfCondition condition={!appKeysLoading}>
                <LoginPage
                    application={application}
                    workspace={workspace}
                    redirectUrl={redirectUrl}
                    isDeeplink={isDeeplink}
                />
            </IfCondition>
            <IfCondition condition={appKeysLoading}>
                <Form showLoading={appKeysLoading}>
                    <FormGroup>
                        <FormControl>
                            <h1>Loading...</h1>
                        </FormControl>
                    </FormGroup>
                </Form>
            </IfCondition>
        </>
    );

    return (
        <div className='App'>
            <UILib>
                <RouterDom.Routes>
                    <RouterDom.Route path={RouterConstants.SIGN_IN} element={mainElement} />
                    <RouterDom.Route
                        path={RouterConstants.SELECT}
                        element={
                            <SelectPage application={application} workspace={workspace} redirectUrl={redirectUrl} />
                        }
                    />
                </RouterDom.Routes>
            </UILib>
        </div>
    );
}

export default App;
