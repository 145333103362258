import { Form, FormControl, SlotConstant } from '@nochiri/ui';
import React from 'react';
import KeyConstant from '../const/KeyConstant';
import IApplicationModel from '../model/ApplicationModel';

interface ILoginSuccessWithDeeplink {
    application?: IApplicationModel;
    deeplink?: string;
    tokenId: string;
}

const LoginSuccessWithDeeplink: React.FC<ILoginSuccessWithDeeplink> = ({
    application,
    deeplink,
    tokenId,
}: ILoginSuccessWithDeeplink) => {
    return (
        <Form
            titleSlot={SlotConstant.CENTER}
            title='Login success'
            description={`Login success to ${application?.name}`}
        >
            <FormControl slot={SlotConstant.CENTER}>
                <a href={`${deeplink}?${KeyConstant.getToken}=${tokenId}`}>Open the app</a>
            </FormControl>
        </Form>
    );
};

export default LoginSuccessWithDeeplink;
