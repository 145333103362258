export interface ILoginParams {
    email: string;
    password: string;
    workspace_id: string;
    application_id: string;
}

export const LOGIN = `
    mutation ($email: String!, $password: String!, $workspace_id: String!, $application_id: String!) {
        login(
            params: { email: $email, password: $password, workspace_id: $workspace_id, application_id: $application_id }
        ) {
            token {
                id
            }
        }
    }
`;
