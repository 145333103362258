import { IComponentError } from '@nochiri/ui';
import ErrorKey from '../const/ErrorKeys';

export const errorMessage = (key: ErrorKey): IComponentError | undefined => {
    switch (key) {
        case ErrorKey.EMAIL_NOT_FOUND: {
            return {
                key: ErrorKey.EMAIL_NOT_FOUND,
                message: 'Email not found',
            };
        }
        case ErrorKey.PASSWORD_INCORRECT: {
            return {
                key: ErrorKey.PASSWORD_INCORRECT,
                message: 'Password incorrect',
            };
        }
        default: {
            return undefined;
        }
    }
};
