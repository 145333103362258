import React from 'react';
import {
    ButtonType,
    CommonButton,
    Form,
    FormControl,
    FormGroup,
    IComponentError,
    IFormElementRef,
    InputText,
    InputType,
    SlotConstant,
} from '@nochiri/ui';
import IWorkspaceModel from '../model/WorkspaceModel';
import ErrorKey from '../const/ErrorKeys';

interface ILoginFormProps {
    workspace: IWorkspaceModel;
    onLogin: (email: string, password: string) => void;
    errors: IComponentError[];
    showLoading?: boolean;
}

const LoginForm: React.FC<ILoginFormProps> = (props: ILoginFormProps) => {
    const formRefs = {
        email: React.createRef<IFormElementRef>(),
        password: React.createRef<IFormElementRef>(),
    };

    const onSubmit = () => {
        if (!formRefs.email.current || !formRefs.password.current) {
            return false;
        }

        if (!formRefs.email.current.validate() || !formRefs.password.current.validate()) {
            return false;
        }

        props.onLogin(formRefs.email.current.value().toLowerCase(), formRefs.password.current.value());
    };

    return (
        <Form
            titleSlot={SlotConstant.CENTER}
            title={`Sign in`}
            description={`Use your ${props.workspace.name} account`}
            onSubmit={onSubmit}
            showLoading={props.showLoading}
        >
            <FormGroup>
                <FormControl>
                    <InputText
                        placeHolder='Email'
                        type={InputType.EMAIL}
                        ref={formRefs.email}
                        rules={[
                            {
                                message: 'Email is require',
                                require: true,
                            },
                        ]}
                        required
                        suffix={`@${props.workspace.email_domain}`}
                        error={props.errors.find((error) => error.key === ErrorKey.EMAIL_NOT_FOUND)}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <InputText
                        placeHolder='Password'
                        type={InputType.PASSWORD}
                        ref={formRefs.password}
                        rules={[
                            {
                                message: 'Password is require',
                                require: true,
                            },
                        ]}
                        required
                        error={props.errors.find((error) => error.key === ErrorKey.PASSWORD_INCORRECT)}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                {props.workspace.allow_sign_up && (
                    <FormControl>
                        <CommonButton type={ButtonType.TEXT_BUTTON} text='Create new account' />
                    </FormControl>
                )}
                <FormControl slot={SlotConstant.END}>
                    <CommonButton
                        isSubmit
                        type={ButtonType.ELEVATED_BUTTON}
                        text='Sign in'
                        loading={props.showLoading}
                    />
                </FormControl>
            </FormGroup>
        </Form>
    );
};

export default LoginForm;
