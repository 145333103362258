import React from "react";
import { Form } from "@nochiri/ui";
import IApplicationModel from "../../model/ApplicationModel";
import IWorkspaceModel from "../../model/WorkspaceModel";
import Style from "./UserSelect.module.css";

interface IUserSelectProps {
    application: IApplicationModel
    workspace: IWorkspaceModel
}

const UserSelect: React.FC<IUserSelectProps> = (props: IUserSelectProps) => {
    return (
        <Form title="Sign in" description={`Select ${props.workspace.name} account your want to sign in to ${props.application.name}`}>
            <div className={Style.user_select_container}>
                <ul>
                    <li>User 1</li>
                    <li>User 2</li>
                    <li>User 3</li>
                </ul>
            </div>
        </Form>
    )
}

export default UserSelect;